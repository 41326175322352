var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "700px", height: "300px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont pop_hscd_area" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.hsCdPop.001"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.287")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.hsCdPop.002")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.hsCdPop.003")))]),
                _vm._v(" "),
                _c("th"),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.hsCdList, function (vo, idx) {
                return _c("tr", { key: "trHsCd_" + idx }, [
                  _c("td", [_vm._v(_vm._s(idx + 1))]),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-input", {
                        attrs: {
                          id: "hsCd_" + idx,
                          "is-upper-case": true,
                          maxlength: "8",
                          "error-message": vo.hscdErrorMessage,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkHscd(idx)
                          },
                        },
                        model: {
                          value: vo.hsCd,
                          callback: function ($$v) {
                            _vm.$set(vo, "hsCd", $$v)
                          },
                          expression: "vo.hsCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(_vm._s(vo.hsNm)),
                  ]),
                  _c("td", [
                    idx === 0
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addHsCd()
                              },
                            },
                          },
                          [_vm._v(" plus ")]
                        )
                      : _vm._e(),
                    idx !== 0
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon minus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.removeHsCd(idx)
                              },
                            },
                          },
                          [_vm._v(" minus ")]
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt20" }, [
          _c(
            "a",
            {
              staticClass: "button lg blue font_16",
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
          ),
          _c(
            "a",
            {
              staticClass: "button lg gray font_16",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.179")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "60%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }