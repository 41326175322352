<template>
  <div class="popup_wrap" style="width:700px; height: 300px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont pop_hscd_area">
      <h1 class="page_title">{{ $t('msg.hsCdPop.001') }}</h1>

      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col width="10%">
            <col width="20%">
            <col width="60%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
              <th>{{ $t('msg.hsCdPop.002') }}</th> <!-- HS CODE -->
              <th>{{ $t('msg.hsCdPop.003') }}</th> <!-- Name -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in hsCdList" :key="'trHsCd_' + idx">
              <td>{{ idx + 1 }}</td>
              <td>
                <e-trans-string-check-input :id="'hsCd_' + idx"
                                            v-model="vo.hsCd"
                                            :is-upper-case="true"
                                            maxlength="8"
                                            :error-message="vo.hscdErrorMessage"
                                            @input="checkHscd(idx)"
                >
                </e-trans-string-check-input>
              </td>
              <td class="text_left">{{ vo.hsNm }}</td>
              <td>
                <button
                  v-if="idx === 0"
                  type="button"
                  class="tbl_icon plus"
                  @click="addHsCd()"
                >
                  plus
                </button>
                <button
                  v-if="idx !== 0"
                  type="button"
                  class="tbl_icon minus"
                  @click="removeHsCd(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="text_center mt20">
        <a class="button lg blue font_16" @click="save()">{{ $t('msg.CSBK100.178') }}</a>
        <a class="button lg gray font_16" @click="$emit('close')">{{ $t('msg.CSBK100.179') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import ETransStringCheckInput from '@/components/trans/ETransStringCheckInput'

export default {
  name: 'HsCodeIDPop',
  components: {
    ETransStringCheckInput
  },
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          blNo: '',
          ldCatCd: '',
          uiId: '',
          ctrCd: ''
        }
      }
    }
  },
  data () {
    return {
      commonCodes: {},
      hsCdList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const params = {
        blNo: this.parentInfo.blNo,
        ldCatCd: this.parentInfo.ldCatCd,
        ctrCd: this.parentInfo.ctrCd
      }
      await trans.findHsCd(params).then(res => {
        const result = res.data
        if (res.headers.respcode === 'C0000' && res.data !== undefined) {
          this.hsCdList = result
        }
      })

      if (this.hsCdList.length === 0) {
        this.addHsCd()
      }
    },
    async checkHscd (idx) {
      const hsCdInfo = this.hsCdList[idx]
      const hsCd = hsCdInfo.hsCd

      const isOk = this.checkValidation(idx)

      if (!isOk) {
        return
      }

      await transCommon.getHscdValidation({
        hsCd: hsCd,
        hsFlag: this.parentInfo.ctrCd,
        hsNmSearchYn: 'Y'
      }).then(res => {
        const result = res.data
        if (result.returnFlag === 'N') {
          this.$set(this.hsCdList[idx], 'hscdErrorMessage', this.$t('msg.CSBL200.175'))
          this.$set(this.hsCdList[idx], 'hsNm', '')
          this.$set(this.hsCdList[idx], 'succYn', 'N')
        } else {
          if (this.$ekmtcCommon.isNotEmpty(result.hsNm)) {
            this.$set(this.hsCdList[idx], 'hsNm', result.hsNm)
          }
          this.$set(this.hsCdList[idx], 'hscdErrorMessage', '')
          this.$set(this.hsCdList[idx], 'succYn', 'Y')
        }
      })
    },
    checkValidation (idx) {
      const hsCdInfo = this.hsCdList[idx]

      let isOk = true
      hsCdInfo.hscdErrorMessage = ''

      if (this.$ekmtcCommon.isNotEmpty(hsCdInfo.hsCd.trim())) {
        if (hsCdInfo.hsCd.length !== 4 && hsCdInfo.hsCd.length !== 6 && hsCdInfo.hsCd.length !== 8) {
          hsCdInfo.hscdErrorMessage = this.$t('msg.CSBL200.175')
          isOk = false
        }

        this.hsCdList.forEach((h, i) => {
          if (i !== idx && h.hsCd === hsCdInfo.hsCd) {
            hsCdInfo.hscdErrorMessage = this.$t('msg.hsCdPop.005')
            isOk = false
          }
        })
      } else {
        isOk = false
        hsCdInfo.hscdErrorMessage = this.$t('msg.CSBK100.147')
      }

      return isOk
    },
    addHsCd () {
      const hsCdList = this.hsCdList
      const seq = hsCdList.length === 0 ? 1 : Number(hsCdList[hsCdList.length - 1].seq) + 1
      const obj = {
        seq: seq,
        hsCd: '',
        hscdErrorMessage: ''
      }

      this.hsCdList.push(obj)
    },
    removeHsCd (idx) {
      const hsCdList = this.hsCdList

      hsCdList.splice(idx, 1)
    },
    save () {
      const hsCdList = this.hsCdList

      let isOk = true
      hsCdList.forEach((h, i) => {
        if (!this.checkValidation(i)) {
          isOk = false
        }

        if (h.succYn === 'N') {
          isOk = false
          h.hscdErrorMessage = this.$t('msg.CSBL200.175')
        }
      })

      if (isOk) {
        const regParams = {
          blNo: this.parentInfo.blNo,
          ldCatCd: this.parentInfo.ldCatCd,
          hsCdList: hsCdList
        }

        let succFlag = true
        trans.saveHsCdMulti(regParams).then(res => {
          if (res.headers.respcode !== 'C0000' || res.data === 'FAIL') {
            succFlag = false
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (succFlag) {
            const TH = this
            this.$ekmtcCommon.alertCallback(this.$t('art.CMATK383'), () => {
              TH.$emit('close')
            })
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
          }
        })
      }
    }
  }
}
</script>
